<template>
  <span>
    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @init-new-row="handleEvent('init-new-row', $event)"
      @option-changed="handleEvent('option-changed', $event)"
      @row-removed="handleEvent('row-removed', $event)"
      @row-click="handleEvent('row-click', $event)"
    >
      <DxEditing
        :allow-updating="!isDisabled"
        :allow-deleting="!isDisabled"
        :allow-adding="!isDisabled"
        :use-icons="!isDisabled"
        mode="row"
      />

      <DxColumn v-if="!isDisabled" type="buttons">
        <DxButton icon="edit" :onClick="editItem" />
        <DxButton name="delete" />
      </DxColumn>

      <DxColumn
        :width="75"
        :allow-sorting="false"
        :allow-filtering="false"
        data-field="thumbnail"
        caption="Preview"
        cell-template="previewThumbnailTemplate"
      />
      <DxColumn data-field="name" />
      <DxColumn data-field="description" />

      <DxColumnChooser :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel location="before" :visible="true" />

      <DxToolbar>
        <DxItem location="before" name="columnChooserButton" />
        <DxItem location="before" name="searchPanel" />
        <DxItem name="groupPanel" />

        <DxItem v-if="!isDisabled" template="addButtonTemplate" />
      </DxToolbar>

      <template #addButtonTemplate>
        <div>
          <b-button
            variant="primary"
            class="text-capitalize"
            @click="addModal = true"
          >
            Add
          </b-button>
        </div>
      </template>

      <template #previewThumbnailTemplate="{ data }">
        <div class="text-center">
          <b-avatar
            v-if="getFileExtension(data) === 'mp4'"
            rounded="sm"
            size="3rem"
          >
            <video controls>
              <source :src="onViewThumbnail(data)" />
            </video>
          </b-avatar>

          <b-avatar
            v-else
            rounded="sm"
            size="3rem"
            :src="onViewThumbnail(data)"
          />
        </div>
      </template>
    </DxDataGrid>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Job Images"
      @hide="closeModal()"
    >
      <b-row>
        <b-col cols="12" :md="formData.base64 ? 4 : 0" v-if="formData.base64">
          <b-img
            style="margin-bottom: 5px"
            height="250"
            width="250"
            thumbnail
            fluid
            :src="getImageSource"
          />
        </b-col>

        <b-col cols="12" :md="formData.base64 ? 8 : 12">
          <b-row>
            <b-col>
              <b-form-group label="Upload File" label-for="onFileUpload">
                <b-form-file
                  accept="image/*"
                  v-model="onFileUpload"
                  placeholder="Choose/Drop a file here..."
                  drop-placeholder="Drop a file here..."
                  id="onFileUpload"
                />
                <b-card-text>
                  Allowed JPG or PNG. Max size of 800kB
                </b-card-text>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="v-name">
                <b-form-input v-model="formData.name" id="v-name" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Description" label-for="v-description">
                <b-form-textarea
                  v-model="formData.description"
                  id="v-description"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: 'JobOrderImagesComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (+this.$route.query.id) {
          if (this.formData.base64) {
            return this.formData.base64;
          }

          if (!this.formData.base64 && this.formData.image_url) {
            return this.formData.image_url;
          }
        }

        return this.formData.base64;
      },
    },
  },
  watch: {
    onFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formData.name = file.name.split('.')[0];
          this.formData.thumbnail = file.name;
          this.formData.base64 = reader.result;
        };
      }
    },
  },
  data: () => ({
    dataIndex: -1,
    addModal: false,
    onFileUpload: null,
    formData: {
      id: 0,
      thumbnail: null,
      base64: null,
    },

    thumbnailpreview: null,
  }),
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        thumbnail: null,
        base64: null,
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formData = Object.assign({}, defaultForm);
      });
    },
    handleEvent(action, event) {
      if (action == 'row-removed') {
        //
      }

      if (action === 'row-click') {
        if (event && event.data) {
          const { data } = event;
          const { id, thumbnail } = data;
          if (data.base64) {
            window.open(data.base64, '_blank');
          }

          const params = {
            model: 'joborder_images',
            id,
            thumbnail,
          };

          window.open(this.getImage(params), '_blank');
        }
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.$nextTick(() => {
          const data = event.row.data;

          const params = {
            model: 'joborder_images',
            id: event.row.key,
            thumbnail: data.thumbnail,
          };

          this.formData.base64 = this.getImage(params);

          this.dataIndex = event.row.dataIndex;
          this.formData = Object.assign({}, data);
        });
      }
    },
    onViewThumbnail(event) {
      if (event.data.base64) {
        return event.data.base64;
      } else {
        const params = {
          model: 'joborder_images',
          id: event.row.key,
          thumbnail: event.row.data.thumbnail,
        };

        return this.getImage(params);
      }
    },
    onSubmit() {
      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formData);
      } else {
        this.dataSource.push(this.formData);
      }

      this.closeModal();
    },
    getFileExtension(data) {
      const [, fileType] = data.value.split('.');

      return fileType;
    },
  },
};
</script>

<style></style>
