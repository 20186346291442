import api from '@apiInstance';

export default {
  add (data) {
    return api.post('joborder_custchecklists', data);
  },
  get (id) {
    return api.fetch('joborder_custchecklists/' + id);
  },
  list (params) {
    return api.fetch('/joborder_custchecklists', { params });
  },
  update (data) {
    return api.patch('joborder_custchecklists', data);
  },
  delete (id) {
    return api.remove('joborder_custchecklists', id);
  }
};
